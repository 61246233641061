import { PageProps } from "gatsby";
import React from "react";

import { RhythmNotation } from "../components/pages/RhythmNotation";

import { snProps } from "../js/utils";

const RhythmNotationPage = (props: PageProps) => (
  <RhythmNotation {...{ ...snProps, ...props }} />
);

export default RhythmNotationPage;
